import * as React from 'react';
import styled from 'styled-components';
import { Header, Layout, SEO } from '../components';

const Spacing = styled.div`
  height: 5rem;
`;
export default class NotFoundPage extends React.Component<any> {
  public render() {
    return (
      <Layout>
          <SEO isPost={false} titlePrefix={'404 not found | '}/>
          <Header title='404' icon='alert' subHeading={'You just hit a route that doesn\'t exist... the sadness.'} />
          <Spacing />
      </Layout>
    );
  }
}
